import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import PageHeader from "../../components/page-header";
import { Wrapper } from "../../components/layout-components";
import Box from "../../components/john-motion/motion-box/index";
import { device } from "../../style/theme";
import Link from "../../components/john-gatsby-helpers/link";
import { Headline } from "../../components/type";
import ImageCover from "../../components/image-cover";
import { makeHover } from "../../style/base-styles";
import SvgLoader from "../../components/svg-loader";
import BannerClassy from "../../components/banner-classy";
import PageHead from "../../components/globals/page-head";

const MenuColumn = styled(Box)`
  display: grid;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  height: 36vw;
  background: ${({ theme }) => theme.colors.silver};
  padding: 0 2rem;
  text-align: center;
  background: ${({ theme }) => theme.colors.golden};
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  border-radius: 15vw;

  @media only screen and ${device.tablet} {
    height: 30vw;
  }

  p.h4 {
    max-width: 0.25em;
  }
`;

const Grid = styled(Wrapper)`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: ${({ theme }) => theme.layout.outerRailBase} auto;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;

  @media only screen and ${device.tablet} {
    margin: ${({ theme }) => theme.layout.outerRailTablet} auto;
  }
`;

const MenuItem = styled(Link)`
  width: 100%;
  transition: transform ${({ theme }) => theme.animation.duration[300].css}
    ${({ theme }) => theme.animation.timingFunction.css};

  @media only screen and ${device.tablet} {
    width: 25%;
  }

  ${makeHover`
    transform: translateY(-0.5rem) scale(1.025);
  `}
`;

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicMenuIndex: {
      data: { title },
    },
    allPrismicMenusMenuCollection: { edges },
    prismicAssets: {
      data: { cover_poster },
    },
    prismicHomepage: {
      data: { classy_cta_action, classy_cta_action_text, classy_cta_image },
    },
  },
}) {
  return (
    <>
      <PageHead title="Menus" />
      <PageHeader title={title} />
      <ImageCover fluid={cover_poster} />
      <Grid>
        {edges.map(({ node: { data, uid } }, i) => (
          <MenuItem to={`${data.path || uid}`} key={i}>
            <MenuColumn>
              <Headline size="h4" lineLength={0.75}>
                {data.page_title}
              </Headline>
              <SvgLoader icon="IconStar" color="black" />
            </MenuColumn>
          </MenuItem>
        ))}
      </Grid>
      <BannerClassy
        classy_cta_image={classy_cta_image}
        classy_cta_action_text={classy_cta_action_text}
        classy_cta_action={classy_cta_action}
      />
    </>
  );
}

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query MenuIndex {
    prismicMenuIndex {
      data {
        top_level_page
        title
        template
        nav_order
        hide_in_nav
        body {
          text
          raw
          html
        }
      }
    }

    allPrismicMenusMenuCollection(
      filter: { tags: { eq: "Petroleum Club" } }
      sort: { fields: data___path, order: ASC }
    ) {
      edges {
        node {
          id
          type
          tags
          uid
          data {
            page_title
            path
          }
        }
      }
    }

    prismicAssets {
      id
      data {
        cover_video {
          id
          url
        }
        cover_poster {
          alt
          copyright
          url
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 2000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }

    prismicHomepage {
      data {
        classy_cta_image {
          fluid(
            placeholderImgixParams: { blur: 600, px: 1 }
            maxWidth: 3000
            imgixParams: { fm: "webp" }
          ) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        classy_cta_action_text
        classy_cta_action
      }
    }
  }
`;
